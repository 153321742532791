import Router from 'vue-router';
import Vue from 'vue';
import { getToken } from '@/utils/auth'
const whiteList = ['/login','/view']
Vue.use(Router)
const vueRouter = new Router({
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.VUE_APP_PATH,
  // mode: process.env.VUE_APP_MODE,
  routes: [
    {
      path: '/login',
      component: () => import( /* webpackChunkName: "page" */ '@/page/login'),
    },
    {
    path: '/',
    component: () => import( /* webpackChunkName: "page" */ '@/page/index'),
    children: [{
      path: '',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list'),
    },
    {
      path: 'category',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list/category'),
    }, {
      path: 'db',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list/db'),
    }, {
      path: 'map',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list/map'),
    }, {
      path: 'document',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list/document'),
    }, {
      path: 'components',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list/components'),

    }, {
      path: 'record',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list/record'),

    }]
  }, {
    path: '/create',
    name: 'create',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/create')
  }, {
    path: '/build/:id',
    name: 'build',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/build')
  }, {
    path: '/view/:id',
    name: 'view',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/view')
  }, /*{
    path: '/mapswiper',
    name: 'mapswiper',
    component: () =>
        import( /!* webpackChunkName: "page" *!/ '@/page/mapswiper')
  }*/

  ]
})

vueRouter.beforeEach((to, from, next) => {
    if(to.name === 'view'){
        next()
    }else{
        if (getToken()) {
            if (to.path === '/login') {
                next({ path: '/' })
            } else {
                next()
            }
        } else {
            if (whiteList.indexOf(to.path) !== -1) {
                // 在免登录白名单，直接进入
                next()
            } else {
                next(`/login`) // 否则全部重定向到登录页
            }
        }
    }
});
export default vueRouter;
/*export default new Router({
    base: process.env.VUE_APP_PATH,
    // mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: vueRouter
})*/
